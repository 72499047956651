var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"pim_main"}},[(_vm.marqueeText)?_c('TopMarquee',{attrs:{"marqueeText":_vm.marqueeText}}):_vm._e(),_vm._v(" "),_c('Header',{attrs:{"marqueeText":_vm.marqueeText}}),_vm._v(" "),_c('client-only',[( this.$route.name == 'product-product')?_c('span',[(
      this.$store.state.list.search_input === '' &&
      this.$route.name != 'index' &&
      this.$route.name != 'checkout' &&
      this.$route.name != 'login' &&
      this.$route.name != 'cart' &&
      this.$route.name != 'thankyou'
    )?_c('nav',{attrs:{"aria-label":"breadcrumb"}},[(_vm.$store.state.list.breadCrumbArray)?_c('ol',{staticClass:"breadcrumb"},_vm._l((_vm.$store.state.list.breadCrumbArray),function(breadcrumb,index){return _c('li',{key:("item-" + (breadcrumb.url_key)),staticClass:"breadcrumb-item font-weight-bold"},[(index == _vm.$store.state.list.breadCrumbArray.length -1)?_c('span',{staticClass:"without-link-breadcrumb"},[_vm._v(_vm._s(breadcrumb.name))]):_c('nuxt-link',{attrs:{"to":{
            path: ("/" + (breadcrumb.name == 'index' ? '' : breadcrumb.url_key)),
          }}},[_vm._v(_vm._s(breadcrumb.name))])],1)}),0):_vm._e()]):_vm._e()]):_c('span',[(
      this.$store.state.list.search_input === '' &&
      this.$store.state.list.breadcrumb != '' &&
      this.$route.name != 'index' &&
      this.$route.name != 'checkout' &&
      this.$route.name != 'login' &&
      this.$route.name != 'cart' &&
      this.$route.name != 'thankyou' &&
      this.$route.name != 'category' &&
      this.$route.name != 'blogs' &&
      this.$route.name != 'blog-blogView'
    )?_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item font-weight-bold"},[_c('nuxt-link',{attrs:{"to":{ path: '/' }}},[_vm._v("Home")])],1),_vm._v(" "),(this.$store.state.list.breadcrumb_second.name != '')?_c('li',{staticClass:"breadcrumb-item font-weight-bold"},[_c('nuxt-link',{attrs:{"to":{ path: this.$store.state.list.breadcrumb_second.url + '' }},domProps:{"textContent":_vm._s(this.$store.state.list.breadcrumb_second.name)}})],1):_vm._e(),_vm._v(" "),_c('li',{staticClass:"breadcrumb-item active",domProps:{"textContent":_vm._s(this.$store.state.list.breadcrumb)}})])]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"container-fluid message-container"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.$store.state.list.page_loader == true),expression:"this.$store.state.list.page_loader == true"}],staticClass:"page_loader",attrs:{"id":"page_loader"}},[_c('img',{attrs:{"src":"/images-walk/preloader.png"}})])]),_vm._v(" "),_c('div',{attrs:{"id":"st-container-hide"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"after-leave":_vm.afterLeave}},[_vm._v(_vm._s(_vm.$route.path)+"\n      "),_c('router-view',{key:_vm.$route.path + _vm.$store.state.list.reactiveComponentCount})],1)],1),_vm._v(" "),(_vm.isShowFooter)?_c('Footer'):_vm._e(),_vm._v(" "),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll-to-top",attrs:{"id":"scroll-to-top"}},[_c('i',{staticClass:"fa fa-chevron-up"})])}]

export { render, staticRenderFns }