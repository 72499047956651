import { render, staticRenderFns } from "./default.vue?vue&type=template&id=386583e2&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopMarquee: require('/srv/users/serverpilot/apps/main/public/components/TopMarquee.vue').default,Header: require('/srv/users/serverpilot/apps/main/public/components/Header.vue').default,Footer: require('/srv/users/serverpilot/apps/main/public/components/Footer.vue').default})
