//
//
//
//
//
//
//
//

export default {
    name: 'SeoContent',
    props: {
      seoContentData: {
        type: String,
        required: true
      }
    }
}
