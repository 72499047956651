//
//
//
//
//
//
//
//

export default {
    props: ['marqueeText'],
}
