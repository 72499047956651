//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import SeoContent from "./SeoContent.vue";
export default {
  name: "Footer",
  data: function () {
    return {
      footer_active: "",
      emailnews: "",
      email_error: "",
      isFooter: false,
    };
  },
  methods: {
    toggleFooter() {
      this.isFooter = !this.isFooter;
    },
    login_popup_show(e) {
      e.preventDefault();
      this.$store.state.list.login_popup = true;
    },
    toggle(name) {
      if (name != this.footer_active) {
        this.footer_active = name;
      } else {
        this.footer_active = "";
      }
    },
    emailValidate() {
      event.preventDefault();
      var email =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/;
      if (this.emailnews.match("gmail")) {
        email = /^[a-z0-9](\.?[a-z0-9]){3,}@g(oogle)?mail\.com$/;
      }
      if (this.emailnews.match(email)) {
        this.email_error = "";
        this.newsletterSubmit();
      } else {
        this.email_error = "Please enter valid Email id.";
      }
    },
    newsletterSubmit() {
      this.$store.state.list.error_message = "";
      this.$store.state.list.success_message = "";
      var form = new FormData();
      form.append("email", this.emailnews);
      axios
        .post(
          this.$store.state.list.cart_api_url + "/newsletter/news-letter",
          form
        )
        .then((response) => {
          if (response.data.success === true) {
            this.$store.state.list.page_loader = false;
            this.$toast.success(response.data.message, {
              position: "top",
              duration: 4000,
            });

            this.emailnews = "";
          } else {
            this.$store.state.list.page_loader = false;
            this.$toast.error(response.data.message, {
              position: "top",
              duration: 4000,
            });
          }
        })
        .catch((error) => {
          this.$store.state.list.page_loader = false;
          if (error.message === "Network Error") {
            this.$store.state.list.error_message = error.message;
          }
        });
    },
  },
  mounted() {
    
    jQuery("body").on("click", ".footer_collapse", function () {
      jQuery(this).toggleClass("active");
      jQuery(this).siblings(".footer_main_container").toggle();
    });
    $("document").ready(function () {
      $(".footer_links .text-white").click(function () {
        $(this).siblings(".customer_menu").toggleClass("footerFadeIn");
      });
    });
  },
};
